import { SpecialKey } from '@dfares/constants';

// planet context pane shortcuts
export const EXIT_PANE = SpecialKey.Escape;
export const TOGGLE_SEND = 'q';
export const TOGGLE_ABANDON = 'w';
export const MINE_ARTIFACT = 'e';
export const TOGGLE_UPGRADES_PANE = 'r';
export const TOGGLE_BROADCAST_PANE = 'a';
export const TOGGLE_PLANET_INFO_PANE = 's';
export const TOGGLE_BUY_ARTIFACT_PANE = 'd';
export const TOGGLE_PLANET_ARTIFACTS_PANE = 'f';
export const TOGGLE_DROP_BOMB_PANE = 'z';
export const TOGGLE_PINK_PANE = 'x';
export const TOGGLE_KARDASHEV_PANE = 'c';
export const TOGGLE_BLUE_PANE = 'v';
export const TOGGLE_HAT_PANE = 'b';
export const INVADE = 'y';

// modal shortcuts
export const MODAL_BACK_SHORTCUT = 't';
export const CLOSE_MODAL = 't';

export const TOGGLE_TRADE_PANE = 'g';
export const TOGGLE_HELP_PANE = 'h';
export const TOGGLE_SETTINGS_PANE = 'j';

export const TOGGLE_PLUGINS_PANE = 'k';
export const TOGGLE_YOUR_ARTIFACTS_PANE = 'l';
export const TOGGLE_YOUR_PLANETS_DEX_PANE = ';';
export const TOGGLE_TRANSACTIONS_PANE = "'";
export const TOGGLE_UNION_PANE = 'u';

// global shortcuts
export const TOGGLE_DIAGNOSTICS_PANE = 'i';
export const TOGGLE_EXPLORE = SpecialKey.Space;
export const TOGGLE_TARGETTING = '`';

export const TOGGLE_TERMINAL = 'n';
export const TOGGLE_PANE_VISIBLE = 'm';
export const TOGGLE_HOTKEY_VISIBLE = ',';
